import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom"; // new import

import "./index.scss";
import "./assets/fontawesome/css/all.css";
import "./assets/fontawesome/css/fontawesome_6.0.css";
import "./assets/text/i18n.js";

import App from "./App";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  < BrowserRouter >
    <App />
  </ BrowserRouter >
);
