
import axios from "axios";

export async function post_log(description = '') {
    var RepData;
    const api_url = 'https://api.sqlbond.com/log/'
    const api_data = { 'description': description, 'session_key': localStorage.getItem('session_key') }
    const api_header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
    }
    try {
        await axios.post(
            api_url,
            api_data,
            { headers: api_header, responseType: 'json' },
        )
            .then((response) => {
                RepData = response.data;
                if (response.data['session_key']) {
                    localStorage.setItem('session_key', response.data['session_key'])
                }
            })
            .catch(() => {
            })
    }
    finally {
        return true;
    }
}

