import React, { Suspense, useEffect, useState, lazy, } from "react";
import { Routes, Route, useLocation } from "react-router-dom"; // new import

import logo from './logo.svg';
import './App.scss';
import { post_log } from './assets/api/post_log.js';

const Main = lazy(() => import("./views/main/main.js"));
const E404 = lazy(() => import("./views/e404/e404.js"));

export default function App() {

  // Run ONCE at start
  useEffect(() => {
    // Log event
    const send_log = async () => {
      await post_log('sqlbond.com');
    }
    send_log();
  }, []);


  return (
    <Suspense fallback={<div></div>}>

      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="*" element={<E404 />} />
        {/* <Route path="/user/:token" element={<USER />} /> */}
      </Routes>

    </Suspense>
  );
}
